import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaRegFileAlt, FaTimesCircle } from 'react-icons/fa';
import { NavLink } from 'reactstrap';
import Modal from '../../modal/modal';
import DownloadComplete from '../download-complete/download-complete';
import { DocsSection } from '../download-section/download-section.css';
import DropdownDocuments from '../dropdown-menu/dropdown-menu';
import { trackEvent } from '../../../helpers/tagManager';

const MultilingualPopup = ({ languages, labels, onClickDownload }) => {
  const [isCompletedDownload, setIsCompletedDownload] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl('');
    setIsCompletedDownload(false);
  }, [isOpenModal]);

  const dropdownLanguagesProps = {
    items: languages,
    preselectedValue: url,
    setSelectedDocument: setUrl,
    title: labels.dropdownLanguageTitle,
    placeholder: labels.dropdownLanguagePlaceholder,
  };

  const downloadCompleteProps = {
    className: 'details-complete',
    labels: {
      title: labels.completeDownloadTitle,
      description: labels.completeDownloadDesc,
      button: labels.completeDownloadBtn,
    },
    setIsCompletedDownload: setIsCompletedDownload,
  };

  const handleClickDownload = ev => {
    onClickDownload(ev);
    trackEvent({
      category: 'Download',
      action: 'download',
      label: labels.title,
    });
    setTimeout(() => {
      setIsCompletedDownload(true);
      setUrl('');
    }, 1000);
  };

  return (
    <div className="attachments">
      <Modal
        open={isOpenModal}
        showModal={() => setIsOpenModal(true)}
        hideModal={() => setIsOpenModal(false)}
        button={labels.title}
        buttonType="simple"
        type={'view-results only-docs'}
        icon={{ element: <FaRegFileAlt />, position: 'left' }}
        trackEvent={{
          label: labels.title
        }}
      >
        <div className={'documents-modal docs-details'}>
          <FaTimesCircle
            onClick={() => setIsOpenModal(false)}
            className={'closeSvg'}
          />
          {isCompletedDownload ? (
            <DownloadComplete {...downloadCompleteProps} />
          ) : (
            <DocsSection>
              <h2>You are about to download</h2>
              <h3>{labels.title}</h3>
              <div className="dropdown-menu-details">
                <DropdownDocuments {...dropdownLanguagesProps} />
              </div>
              <NavLink
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="download-btn text-uppercase"
                aria-disabled={url === '' ? true : false}
                disabled={url === '' ? true : false}
                onClick={ev => handleClickDownload(ev)}
              >
                {labels.downloadBtn}
              </NavLink>
            </DocsSection>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MultilingualPopup;

MultilingualPopup.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  labels: PropTypes.shape({
    title: PropTypes.string.isRequired,
    dropdownLanguageTitle: PropTypes.string.isRequired,
    dropdownLanguagePlaceholder: PropTypes.string.isRequired,
    downloadBtn: PropTypes.string.isRequired,
    completeDownloadTitle: PropTypes.string.isRequired,
    completeDownloadDesc: PropTypes.string.isRequired,
    completeDownloadBtn: PropTypes.string.isRequired,
  }),
  onClickDownload: PropTypes.func.isRequired,
};
