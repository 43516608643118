import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FaTimesCircle } from 'react-icons/fa';
import { ContactDiv, ContactStyle } from './contact.css';
import { registerTrackEvent } from '../../helpers/tagManager';

const ContactUsWidget = ({
  personName,
  personPhone,
  personEmail,
  centerEmail,
  centerPhone,
  hideModal,
  centerName,
  centerBkpEmail,
  centerBkpPhone,
  centerBkpName,
  print,
}) => {
  return (
    <>
      {!print ? (
        <ContactStyle>
          <FaTimesCircle onClick={hideModal} />
          <ContactDiv>
            <Row>
              <Col className={'col-style left-col'} md={6}>
                <h2>{'Site Contact'}</h2>
                <h3>{'Person of Contact:'}</h3>
                {personName.indexOf('null') < 0 ||
                personEmail ||
                personPhone ? null : (
                  <p>{'Not Available'}</p>
                )}
                {personName.indexOf('null') < 0 ? <p>{personName}</p> : null}
                {personPhone ? (
                  <a
                    rel="noopener noreferrer"
                    href={`tel:${personPhone}`}
                    onClick={
                      registerTrackEvent({
                        category: 'CTAclick',
                        action: 'cta link',
                        value: personEmail
                      })
                    }
                  >
                    {personPhone}
                  </a>
                ) : null}{' '}
                {personEmail ? (
                  <a
                    target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${personEmail}`}
                    onClick={
                      registerTrackEvent({
                        category: 'CTAclick',
                        action: 'cta link',
                        value: personEmail
                      })
                    }
                  >
                    {personEmail}
                  </a>
                ) : null}
              </Col>
              <Col className={'col-style right-col'} md={6}>
                <h2>{'Sponsor Contact'}</h2>
                <h3>{'Central Contact:'}</h3>
                {/* <p>{'US Medical Information:'}</p>
                  <a rel="noopener noreferrer" href={`tel:${'888-275-7376'}`}>
                    {'888-275-7376'}
                  </a>
                  <h3>{'Central Contact Backup:'}</h3>
                  <p>{'Comunication Center:'}</p> */}
                {centerName && (centerPhone || centerEmail) ? (
                  <div className={'contact-row'}>
                    <p>{centerName}</p>
                    <a
                      rel="noopener noreferrer"
                      href={`tel:${centerPhone}`}
                      onClick={
                        registerTrackEvent({
                          category: 'CTAclick',
                          action: 'cta link',
                          value: centerPhone
                        })
                      }
                    >
                      {centerPhone}
                    </a>
                    {centerPhone && centerEmail ? <span>{'or'}</span> : null}
                    <a
                      target="_top"
                      rel="noopener noreferrer"
                      href={`mailto:${centerEmail}`}
                      onClick={
                        registerTrackEvent({
                          category: 'CTAclick',
                          action: 'cta link',
                          value: centerEmail
                        })
                      }
                    >
                      {centerEmail}
                    </a>
                  </div>
                ) : (
                  <p>{'Not Available'}</p>
                )}

                {centerBkpName && (centerBkpPhone || centerBkpEmail) ? (
                  <div>
                    <h3>{'Central Contact Backup:'}</h3>
                    <div className={'contact-row'}>
                      <p>{centerBkpName}</p>
                      <a
                        rel="noopener noreferrer"
                        href={`tel:${centerBkpPhone}`}
                        onClick={
                          registerTrackEvent({
                            category: 'CTAclick',
                            action: 'cta link',
                            value: centerBkpPhone
                          })
                        }
                      >
                        {centerBkpPhone}
                      </a>
                      {centerBkpPhone && centerBkpEmail ? (
                        <span>{'or'}</span>
                      ) : null}
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={`mailto:${centerBkpEmail}`}
                        onClick={
                          registerTrackEvent({
                            category: 'CTAclick',
                            action: 'cta link',
                            value: centerBkpEmail
                          })
                        }
                      >
                        {centerBkpEmail}
                      </a>
                    </div>
                  </div>
                ) : null}
              </Col>
            </Row>
          </ContactDiv>
        </ContactStyle>
      ) : (
        <ContactDiv className={'print-section'}>
          <Row>
            <Col className={'col-style left-col'} md={4}>
              <h2>{'Site Contact'}</h2>
              <h3>{'Person of Contact:'}</h3>
              {personName.indexOf('null') < 0 ||
              personEmail ||
              personPhone ? null : (
                <p>{'Not Available'}</p>
              )}
              {personName.indexOf('null') < 0 ? <p>{personName}</p> : null}
              {personPhone ? (
                <a rel="noopener noreferrer" href={`tel:${personPhone}`}>
                  {personPhone}
                </a>
              ) : null}{' '}
              {personEmail ? (
                <a
                  target="_top"
                  rel="noopener noreferrer"
                  href={`mailto:${personEmail}`}
                >
                  {personEmail}
                </a>
              ) : null}
            </Col>
            <Col className={'col-style right-col'} md={8}>
              <Col xs={12}>
                <h2>{'Sponsor Contact'}</h2>
              </Col>
              <Col xs={12} md={6}>
                <h3>{'Central Contact:'}</h3>
                {/* <p>{'US Medical Information:'}</p>
                  <a rel="noopener noreferrer" href={`tel:${'888-275-7376'}`}>
                    {'888-275-7376'}
                  </a>
                  <h3>{'Central Contact Backup:'}</h3>
                  <p>{'Comunication Center:'}</p> */}
                {centerName && (centerPhone || centerEmail) ? (
                  <div className={'contact-row'}>
                    <p>{centerName}</p>
                    <a rel="noopener noreferrer" href={`tel:${centerPhone}`}>
                      {centerPhone}
                    </a>
                    {centerPhone && centerEmail ? <span>{'or'}</span> : null}
                    <a
                      target="_top"
                      rel="noopener noreferrer"
                      href={`mailto:${centerEmail}`}
                    >
                      {centerEmail}
                    </a>
                  </div>
                ) : (
                  <p>{'Not Available'}</p>
                )}
              </Col>
              <Col xs={12} md={6}>
                {centerBkpName && (centerBkpPhone || centerBkpEmail) ? (
                  <div>
                    <h3>{'Central Contact Backup:'}</h3>
                    <div className={'contact-row'}>
                      <p>{centerBkpName}</p>
                      <a
                        rel="noopener noreferrer"
                        href={`tel:${centerBkpPhone}`}
                      >
                        {centerBkpPhone}
                      </a>
                      {centerBkpPhone && centerBkpEmail ? (
                        <span>{'or'}</span>
                      ) : null}
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={`mailto:${centerBkpEmail}`}
                      >
                        {centerBkpEmail}
                      </a>
                    </div>
                  </div>
                ) : null}
              </Col>
            </Col>
          </Row>
        </ContactDiv>
      )}{' '}
    </>
  );
};

ContactUsWidget.propTypes = {
  personName: PropTypes.string,
  personPhone: PropTypes.string,
  personEmail: PropTypes.string,
  centerName: PropTypes.string,
  centerEmail: PropTypes.string,
  centerPhone: PropTypes.string,
  centerBkpName: PropTypes.string,
  centerBkpEmail: PropTypes.string,
  centerBkpPhone: PropTypes.string,
  hideModal: PropTypes.func,
  print: PropTypes.bool,
};

export default ContactUsWidget;
