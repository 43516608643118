import React from 'react';
import PropTypes from 'prop-types';
import { FaTimesCircle } from 'react-icons/fa';
import { CriteriaDiv, ContainerCrt } from './criteria.css';

const Criteria = ({ textExc, textInc, hideModal }) => {
  return (
    <ContainerCrt>
      <FaTimesCircle onClick={hideModal} />
      <CriteriaDiv>
        <h2>{'Inclusion Criteria'}</h2>
        {textInc ? (
          <pre>{textInc}</pre>
        ) : (
          <pre>{'Sorry there is no Inclusion Criteria'}</pre>
        )}

        <h2 className={'exclusion'}>{'Exclusion Criteria'}</h2>
        {textExc ? (
          <pre>{textExc}</pre>
        ) : (
          <pre>{'Sorry there is no Exclusion Criteria'}</pre>
        )}
      </CriteriaDiv>
    </ContainerCrt>
  );
};

Criteria.propTypes = {
  textInc: PropTypes.string,
  textExc: PropTypes.string,
  hideModal: PropTypes.any,
};

export default Criteria;
