import styled from 'styled-components';
import { borderContactUS } from '../../constants/theme';

export const ContactStyle = styled.div`
  svg {
    font-size: 30px;
    float: right;
    cursor: pointer;
    color: #eb3c96;
    margin-right: 15px;
    &:hover {
      color: #a52a69;
    }
  }
`;

export const ContactDiv = styled.div`
  padding: 40px;
  h2 {
    color: #503291;
    font-size: 30px;
    font-weight: 900;
    line-height: 36px;
  }
  h3 {
    color: #000000;
    font-size: 22px;
    font-weight: 900;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  p {
    color: #000000;
    font-size: 18px;
    line-height: 32px;
  }
  a {
    color: #eb3c96;
    font-size: 18px;
    line-height: 32px;
    &:hover {
      text-decoration: none;
      color: #a52a69;
    }
  }
  .col-style {
    display: flex;
    flex-direction: column;
    &.left-col {
      border-right: 1px solid ${borderContactUS};
    }
    &.right-col {
      padding-left: 50px;
    }
    h3 {
      font-weight: 700;
    }
  }
  .contact-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    p {
      margin: 0;
      display: block;
      width: 100%;
    }
    span {
      margin: 0 10px;
      color: #000000;
      font-size: 18px;
      line-height: 32px;
    }
  }
  &.print-section {
    padding: 0 0 20px 0;
    h2 {
      padding-top: 10px;
      font-size: 18px;
      line-height: 21px;
    }
    h3 {
      margin: 0;
      font-size: 16px;
      line-height: 27px;
    }
    p {
      font-size: 16px;
      line-height: 27px;
    }
    a {
      font-size: 16px;
      line-height: 21px;
    }
    .col-style {
      display: flex;
      flex-direction: column;
      &.left-col {
        padding-left: 35px;
      }
      &.right-col {
        padding-left: 5px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .contact-row {
      display: block;
    }
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    .col-style {
      &.left-col {
        border: none;
      }
      &.right-col {
        padding-left: 15px;
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid ${borderContactUS};
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .col-style {
      h3 {
        margin-top: 10px;
      }
      &.right-col {
        margin-top: 10px;
        padding-top: 10px;
      }
    }
  }
`;
