import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaRegFileAlt } from 'react-icons/fa';
import { DocumentsSection } from '../../../global.css';
import { trackAnalytics } from '../../../helpers/trackAnalytics';
import Modal from '../../modal/modal';
import Redirect from '../../redirect/redirect';
import MultilingualPopup from '../multilingual-popup/multilingual-popup';
import TooltipDoc from '../tooltip-doc/tooltip-doc';
import { getAttachmentsGroupedByType } from './download-section-results';

const DownloadSectionDetails = ({
  study,
  labels,
  tooltips,
  isMobile,
  companyData,
}) => {
  const [showExternalLinkModal, setShowExternalLinkModal] = useState('');
  const [isOpenExtModal, setIsOpenExtModal] = useState(false);

  useEffect(() => {
    if (isOpenExtModal === false) {
      setShowExternalLinkModal('');
    }
  }, [isOpenExtModal]);

  const trackLink = (event, attachment) => {
    trackAnalytics('DocumentDownload', {
      attachment: attachment,
      TrialId: study.org_study_id,
      TrialTitle: study.brief_title,
      TrialStatus: study.display_status_internal,
      Conditions: study.conditions.map((cond) => cond.InternalValue),
    });

    if (event.target && typeof window !== 'undefined' && window.ga) {
      try {
        window.ga.getAll().forEach((_ga) => {
          _ga.send('event', 'Downloads', 'Downloads', event.target.href);
        });
      } catch (ev) {
        console.log(ev);
      }
    }
  };

  const getAttachments = () => {
    let attachments = getAttachmentsGroupedByType(study.attachments);

    const displayIndividualPatientDataBtn = study.fields.filter(
      (field) => field.name === 'Patient Level Data' && field.value === 'Yes'
    ).length;
    if (displayIndividualPatientDataBtn > 0) {
      attachments = {
        ...attachments,
        INDIVIDUAL_PATIENT_DATA: [
          {
            typeDisplay: 'Individual Patient Data',
            value: companyData,
          },
        ],
      };
    }

    if (study.HasResults === 'true' && study.nct_number) {
      attachments = {
        ...attachments,
        STUDY_WITH_RESULTS: [
          {
            typeDisplay: 'Study Results',
            value: `https://clinicaltrials.gov/ct2/show/results/${study.nct_number}?term=${study.nct_number}&rank=1`,
            onClick: (ev) => {
              ev.preventDefault();
              if (
                ev &&
                ev.target &&
                ev.target.href &&
                (ev.target.href.indexOf('http://') > -1 ||
                  ev.target.href.indexOf('https://') > -1)
              ) {
                setIsOpenExtModal(true);
                setShowExternalLinkModal(ev.target.href);
              }
            },
          },
        ],
      };
    }

    return attachments;
  };

  const getTooltipProps = (key, display) => {
    switch (key) {
      case 'ATTACHTYPE_SAP':
        return {
          id: 'studySynopsis',
          popoverTitle: display,
          popoverDescription: tooltips.statisticalPlan,
          trigger: isMobile ? 'click' : 'hover',
          placement: isMobile ? 'left' : 'right',
        };
      case 'ATTACHTYPE_PROTOCOL':
        return {
          id: 'protocol',
          popoverTitle: display,
          popoverDescription: tooltips.protocol,
          trigger: isMobile ? 'click' : 'hover',
          placement: isMobile ? 'right' : 'right',
        };
      case 'ATTACHTYPE_CSR_SYNOPSIS':
        return {
          id: 'csr_Synopsis',
          popoverTitle: display,
          popoverDescription: tooltips.clinicalStudyReport,
          trigger: isMobile ? 'click' : 'hover',
          placement: isMobile ? 'left' : 'right',
        };
      case 'ATTACHTYPE_RESULTS_SUMMARY':
      case 'ATTACHTYPE_LAY_LANGUAGE_SUMMARY':
        return {
          id: 'result_summary',
          popoverTitle: display,
          popoverDescription: tooltips.resultSummary,
          trigger: isMobile ? 'click' : 'hover',
          placement: isMobile ? 'left' : 'right',
        };
      case 'STUDY_WITH_RESULTS':
        return {
          id: 'studyResult',
          popoverTitle: display,
          popoverDescription: tooltips.studyResults,
          trigger: isMobile ? 'click' : 'hover',
          placement: isMobile ? 'left' : 'right',
        };
      default:
        return null;
    }
  };

  const attachments = getAttachments();

  return (
    <DocumentsSection>
      {attachments &&
        Object.keys(attachments).map((type, index) => (
          <div key={index} className={'documents-holder'}>
            {attachments[type].length > 1 ? (
              <MultilingualPopup
                languages={attachments[type]}
                labels={{ ...labels, title: attachments[type][0].typeDisplay }}
                onClickDownload={(ev) => trackLink(ev, type)}
              />
            ) : (
              <div className={'attachments'}>
                <a
                  className={'documents'}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={attachments[type][0].value}
                  onClick={(ev) => {
                    trackLink(ev, type);
                    if (typeof attachments[type][0].onClick == 'function') {
                      attachments[type][0].onClick(ev);
                    }
                  }}
                >
                  <FaRegFileAlt />
                  {attachments[type][0].typeDisplay}
                </a>
              </div>
            )}
            {getTooltipProps(type, attachments[type][0].typeDisplay) && (
              <TooltipDoc
                {...getTooltipProps(type, attachments[type][0].typeDisplay)}
              />
            )}
          </div>
        ))}
      {showExternalLinkModal && showExternalLinkModal.length && (
        <Modal
          autoOpen={true}
          open={isOpenExtModal}
          type={'redirect'}
          hideModal={() => setIsOpenExtModal(false)}
          trackEvent={{
            label: "Redirect Popup"
          }}
        >
          <Redirect
            hideModal={() => {
              setIsOpenExtModal(false);
            }}
            url={showExternalLinkModal}
          />
        </Modal>
      )}
    </DocumentsSection>
  );
};

export default DownloadSectionDetails;

DownloadSectionDetails.propTypes = {
  study: PropTypes.object.isRequired,
  labels: PropTypes.shape({
    dropdownLanguageTitle: PropTypes.string.isRequired,
    dropdownLanguagePlaceholder: PropTypes.string.isRequired,
    downloadBtn: PropTypes.string.isRequired,
    completeDownloadTitle: PropTypes.string.isRequired,
    completeDownloadDesc: PropTypes.string.isRequired,
    completeDownloadBtn: PropTypes.string.isRequired,
  }),
  tooltips: PropTypes.shape({
    statisticalPlan: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired,
    clinicalStudyReport: PropTypes.string.isRequired,
    resultSummary: PropTypes.string.isRequired,
    studyResults: PropTypes.string.isRequired,
  }),
  isMobile: PropTypes.bool.isRequired,
  companyData: PropTypes.any.isRequired,
};
