import PropTypes from 'prop-types';
import React from 'react';
import { FaInfo } from 'react-icons/fa';
import { Button, PopoverBody, PopoverHeader } from 'reactstrap';
import { UncontrolledPopoverStyledSections } from '../../../global.css';

const TooltipDoc = ({
  id,
  popoverTitle,
  popoverDescription,
  trigger,
  placement,
}) => {
  return (
    <div className={'popoverHolder'}>
      <Button id={id} type="right" className={'popoverBtn'}>
        <FaInfo />
      </Button>
      <UncontrolledPopoverStyledSections
        trigger={trigger}
        placement={placement}
        target={id}
      >
        <PopoverHeader>{popoverTitle}</PopoverHeader>
        <PopoverBody>{popoverDescription}</PopoverBody>
      </UncontrolledPopoverStyledSections>
    </div>
  );
};

export default TooltipDoc;

TooltipDoc.propTypes = {
  id: PropTypes.string.isRequired,
  popoverTitle: PropTypes.string.isRequired,
  popoverDescription: PropTypes.string.isRequired,
  trigger: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
};
