import styled from 'styled-components';

export const ContainerCrt = styled.div`
  svg {
    font-size: 30px;
    float: right;
    cursor: pointer;
    color: #eb3c96;
    margin-top: -30px;
    margin-right: 15px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    svg {
      margin-top: 0;
      margin-right: 5px;
    }
    h2 {
      margin-bottom: 10px !important;
    }
    h2.exclusion {
      margin-top: 30px;
    }
    pre {
      margin 0 20px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    svg {
      margin-top: 0;
      margin-right: 5px;
    }
    h2 {
      margin-bottom: 10px !important;
    }
    h2.exclusion {
      margin-top: 30px;
    }
    pre {
      margin 0 20px !important;
    }
  }
`;

export const CriteriaDiv = styled.div`
  padding: 0 20px;
  h2 {
    color: #503291;
    font-size: 30px;
    margin: 40px 0;
    font-weight: 900;
    line-height: 29px;
  }
  pre {
    color: #000000;
    font-size: 18px;
    line-height: 32px;
    border: none;
    white-space: pre-line;
    padding: 0;
    font-family: inherit;
    font-weight: 300;
    margin: 0 40px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  }

  //TABLET Landscape
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .criteria-icon {
      margin-left: -20px;
    }
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 40px 0px !important;
    h1 {
      text-align: center;
      font-size: 21px;
    }
    h2 {
      margin-top: 0px;
      font-size: 18px;
      line-height: 45px;
      text-align: center;
      display: inline-block;
      vertical-align: bottom;
    }
    pre {
      font-size: 16px;
      line-height: 21px;
      padding-top: 15px;
    }
    .criteria-icon-mobile {
      margin-right: 15px;
      margin-top: 30px;
      height: 45px;
      width: 45px;
      display: inline-block;
    }
    .criteria-icon {
      display: none;
    }
  }
`;
