import axios from 'axios';
import { graphql } from 'gatsby';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FaInfo } from 'react-icons/fa';
import Moment from 'react-moment';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {
  Button,
  Card,
  CardBody,
  Col,
  PopoverBody,
  PopoverHeader,
  Row,
  Spinner,
} from 'reactstrap';
import ContactUsWidget from '../components/contact/contact-us-widget';
import Criteria from '../components/criteria/criteria';
import DownloadSectionDetails from '../components/documents-popup/download-section/download-section-details';
import Layout from '../components/layout/layout';
import Modal from '../components/modal/modal';
import SocialSharing from '../components/social-sharing/social-sharing';
import {
  getCompanyData,
  gMapApiKey,
  replaceCompanyName,
  trialDetailsUrl,
} from '../constants/config';
import {
  DetailsHero,
  Fonts,
  MapSectionStyle,
  ShadowSecDetails,
  StudyNotFoundContainer,
  TrialPurposeStyle,
  TrialSummaryStyle,
  UncontrolledPopoverStyledSections,
} from '../global.css';
import { trackAnalytics } from '../helpers/trackAnalytics';

// import ModalSocial from '../containers/modal/modal-social';

const TrialDetails = ({ location, data, google }) => {
  const [searchResult, setSearchResult] = useState(null);
  const [searchParams, setSearchParams] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [activeMarker, setActiveMarker] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [crtModalOpen, setCrtModalOpen] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [searchLat, setSearchLat] = useState(null);
  const [searchLong, setSearchLong] = useState(null);
  const [searchName, setSearchName] = useState(null);
  const [radius, setRadius] = useState(null);
  const [activeLocations, setActiveLocations] = useState([]);
  const [apiBadResponse, setApiBadResponse] = useState(false);
  const [showCitations, setShowCitations] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [markerDescriptions, setMarkerDescriptions] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [eudraCtId, setEudraCtId] = useState(null);
  const [ctisId, setCtisId] = useState(null);

  const mapRef = useRef();

  useEffect(() => {
    NProgress.start();
    loadData();
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth && window.innerWidth < 992);
      setLoading(false);
    }
  }, []);

  const filteringUniq = (content) => {
    let uniq = [];
    return content.filter((item) => {
      if (!uniq.includes(item)) {
        uniq.push(item);
        return true;
      }
      return false;
    });
  };

  const showCitationsFnc = () => {
    setShowCitations(!showCitations);
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const openLocationTable = () => {
    setOpenTable(true);
  };

  const closeLocationTable = () => {
    setOpenTable(false);
  };

  const showCrtModal = () => {
    setCrtModalOpen(true);
  };

  const hideCrtModal = () => {
    setCrtModalOpen(false);
  };

  const calculateDistance = (locLat, locLong, paramLong, paramLat) => {
    let searchLat = (Math.PI * parseFloat(paramLat).toFixed(6)) / 180;
    let radLocLat = (Math.PI * locLat) / 180;
    let theta = paramLong - locLong;
    let radtheta = (Math.PI * theta) / 180;
    let distance =
      Math.sin(searchLat) * Math.sin(radLocLat) +
      Math.cos(searchLat) * Math.cos(radLocLat) * Math.cos(radtheta);
    distance = Math.acos(distance);
    distance = (distance * 180) / Math.PI;
    distance = distance * 60 * 1.1515;
    return distance;
  };

  const sortLocations = (paramRes, paramLong, paramLat, paramName) => {
    if (paramLong != null && paramLat != null) {
      paramRes.forEach((loc) => {
        loc[paramName] = calculateDistance(
          loc['Latitude'],
          loc['Longitude'],
          paramLong,
          paramLat
        );
      });

      paramRes.sort(function (a, b) {
        return a.distance - b.distance;
      });
      if (
        paramRes.length > 1 &&
        paramRes[0].distance === paramRes[1].distance &&
        paramRes[0].Name !== searchName &&
        paramRes[0].Latitude === paramLat
      ) {
        Array.prototype.swap = function (index_A, index_B) {
          var input = this;
          var temp = input[index_A];
          input[index_A] = input[index_B];
          input[index_B] = temp;
        };
        paramRes.swap(
          0,
          paramRes.findIndex(({ Name }) => Name === `${searchName}`)
        );
      }
      return paramRes;
    } else {
      return paramRes;
    }
  };

  const loadData = () => {
    let tempSearchParams = searchParams,
      tempElementId = null,
      tempSearchLat = null,
      tempSearchLong = null,
      tempSearchRadius = null,
      baseLat = null,
      baseLong = null,
      locationName = null;
    try {
      tempSearchParams = new URL(location.href).searchParams;
      setSearchParams(tempSearchParams);
      tempElementId = tempSearchParams.get('id');
      tempSearchLat = tempSearchParams.get('searchLat');
      tempSearchLong = tempSearchParams.get('searchLong');
      tempSearchRadius = tempSearchParams.get('searchRadius');
      baseLat = tempSearchParams.get('baseLat');
      baseLong = tempSearchParams.get('baseLong');
      locationName = tempSearchParams.get('name');
    } catch (e) {
      tempElementId = location.search.substr(4);
    }

    setIsLoading(true);
    setSearchLat(tempSearchLat);
    setSearchLong(tempSearchLong);
    setRadius(tempSearchRadius);
    setSearchName(locationName);

    axios
      .get(trialDetailsUrl(tempElementId))
      .then((res) => {
        NProgress.done();
        const searchResults = res.data;
        let tempTitleToggle = {};
        const bounds = new window.google.maps.LatLngBounds();
        let tempMarkers = [];
        let tempMarkerDescriptions = {};
        let resultsWithLocation = 0;

        trackAnalytics('TrialDetailsResponse', {
          TrialId: tempElementId,
          tempSearchLat,
          tempSearchLong,
          tempSearchRadius,
          baseLat,
          baseLong,
          Success: true,
        });

        if (
          searchResults &&
          searchResults.study_id &&
          searchResults.protocol_form &&
          searchResults.protocol_form.locations
        ) {
          setSearchResult(searchResults.protocol_form);
          if (
            searchResults.protocol_form.secondary_ids.length &&
            searchResults.protocol_form.secondary_ids.find((ids) =>
              ids.id_type.includes('EudraCT')
            )
          ) {
            setEudraCtId(
              searchResults.protocol_form.secondary_ids &&
                searchResults.protocol_form.secondary_ids.find(
                  (ids) => ids.id_type === 'EudraCT Number'
                ).id
            );
          }
          if (searchResults.protocol_form.ctis_id) {
            setCtisId(searchResults.protocol_form.ctis_id);
          }
          let locations = sortLocations(
            searchResults.protocol_form.locations,
            baseLong,
            baseLat,
            'dis',
            null
          );
          locations.forEach((row, index) => {
            if (row.Latitude && row.Longitude) {
              const key =
                '' +
                parseInt(row.Latitude * 10000) +
                '_' +
                parseInt(row.Longitude * 10000);
              if (typeof tempMarkerDescriptions[key] === 'undefined') {
                tempMarkerDescriptions[key] = [];
              }
              tempMarkerDescriptions[key].push(row);

              const loc = new window.google.maps.LatLng(
                row.Latitude,
                row.Longitude
              );

              const marker = new window.google.maps.Marker({
                position: loc,
                title: row.Title,
                markersClusterKey: key,
                locationDetails: row,
                draggable: false,
                lat: row.Latitude,
                long: row.Longitude,
                distance: row.dis,
                icon: {
                  url: '/images/marker.png',
                },
              });
              marker.addListener('click', () => {
                onPinClick({ locationDetails: row }, marker);
              });

              tempMarkers[index] = marker;
              bounds.extend(loc);
              resultsWithLocation++;
            }

            tempTitleToggle[row.Name] = false;
          });

          setTimeout(() => {
            if (
              mapRef &&
              mapRef.current.map &&
              mapRef.current.map.fitBounds &&
              resultsWithLocation
            ) {
              if (baseLat && baseLong && tempSearchRadius) {
                mapRef.current.map.setCenter({
                  lat: parseFloat(baseLat),
                  lng: parseFloat(baseLong),
                });
                mapRef.current.map.setZoom(
                  tempSearchRadius === '10'
                    ? 9
                    : tempSearchRadius === '50'
                    ? 8
                    : tempSearchRadius === '100'
                    ? 7
                    : tempSearchRadius === '150'
                    ? 6
                    : tempSearchRadius === '200'
                    ? 5
                    : 4
                );
                {
                  tempMarkers.forEach((marker) => {
                    if (marker.distance < tempSearchRadius) {
                      marker.setVisible(true);
                    } else {
                      marker.setVisible(false);
                    }
                  });
                }

                tempMarkers.forEach((marker) => {
                  marker.setMap(mapRef.current.map);
                });

                mapRef.current.map.addListener('click', () => {
                  onMapClicked();
                });
                mapRef.current.map.addListener('zoom_changed', () => {
                  onZoomChanged();
                });

                window.google.maps.event.addListener(
                  mapRef.current.map,
                  'bounds_changed',
                  () => {
                    onBoundsChanged(searchResults.protocol_form, tempMarkers);
                  }
                );
              } else {
                mapRef.current.map.fitBounds(bounds);
                tempMarkers.forEach((marker) => {
                  marker.setMap(mapRef.current.map);
                });
                window.google.maps.event.addListener(
                  mapRef.current.map,
                  'bounds_changed',
                  () => {
                    onBoundsChanged(searchResults.protocol_form, tempMarkers);
                  }
                );
              }
              resultsWithLocation === 1 ? mapRef.current.map.setZoom(1) : null;
            }
            setMarkers(tempMarkers);
            setMarkerDescriptions(tempMarkerDescriptions);
          }, 2000);
          setIsLoading(false);
          setShowingInfoWindow(false);
          setActiveMarker({});
        }
      })
      .catch((error) => {
        trackAnalytics('TrialDetailsResponseFailure', {
          Error: error.message,
          TrialId: tempElementId,
          tempSearchLat,
          tempSearchLong,
          tempSearchRadius,
          baseLat,
          baseLong,
        });
        NProgress.done();
        setApiBadResponse(true);
      });
  };

  const onPinClick = (props, marker) => {
    if (marker && marker.getPosition()) {
      mapRef.current.map.setCenter(marker.getPosition());
    }

    setActiveMarker(marker);
    // setSelectedPlace(props);
    setShowingInfoWindow(true);
  };

  const onZoomChanged = () => {
    markers.forEach((marker) => {
      marker.setVisible(true);
    });
    setRadius(null);
  };

  const onBoundsChanged = (paramSearchResult, paramMarkers) => {
    let currentLocations = [];
    let tempActiveLocations = [];
    let uniqueLocations = [];

    for (var i = 0; i < paramMarkers.length; i++) {
      if (
        mapRef.current.map.getBounds().contains(paramMarkers[i].getPosition())
      ) {
        let key = '';
        currentLocations.push({
          Latitude: paramMarkers[i].lat,
          Longitude: paramMarkers[i].long,
          key: key.concat(paramMarkers[i].lat, paramMarkers[i].long),
        });
        // markers[i] in visible bounds
      } else {
        // markers[i] is not in visible bounds
      }
    }
    uniqueLocations = Array.from(
      new Set(currentLocations.map((a) => a.key))
    ).map((key) => {
      return {
        Latitude: currentLocations.find((s) => s.key === key).Latitude,
        Longitude: currentLocations.find((s) => s.key === key).Longitude,
      };
    });
    paramSearchResult.locations.map((item) => {
      uniqueLocations.map((activeItem) => {
        if (
          item.Latitude.includes(activeItem.Latitude) &&
          item.Longitude.includes(activeItem.Longitude) &&
          (item.dis < radius || radius === null)
        ) {
          tempActiveLocations.push(item);
        }
      });
    });
    setActiveLocations(tempActiveLocations);
  };

  const onInfoWindowClose = () => {
    setActiveMarker(null);
    setShowingInfoWindow(false);
  };

  const onMapClicked = () => {
    if (showingInfoWindow) {
      setActiveMarker(null);
      setShowingInfoWindow(false);
    }
  };

  const renderTableRow = (row, index) => {
    return (
      <Fragment key={index}>
        <Tr
          className={
            'location-row no-print ' +
            (index % 2 === 1 ? 'row-unpaired' : 'row-paired') +
            (index <= 3 && openTable === false
              ? ' showRow'
              : openTable === true
              ? ' showTotalRows'
              : ' hideRow')
          }
        >
          <Td className={'location'}>{row.Name}</Td>
          {row.City ? <Td>{row.City}</Td> : <Td>N/A</Td>}
          {row.StateDisplay ? <Td>{row.StateDisplay}</Td> : <Td>N/A</Td>}
          {row.PostalCode ? <Td>{row.PostalCode}</Td> : <Td>N/A</Td>}
          {row.CountryDisplay ? <Td>{row.CountryDisplay}</Td> : <Td>N/A</Td>}
          <Td className={'status-line'}>
            <div
              className={
                `${row.StatusMapped}`.toLocaleLowerCase() + ' status-content'
              }
            >
              {row.StatusMapped}
            </div>
          </Td>
          <Td className={'modal-status'}>
            <Modal
              type={'contactUs'}
              open={modalOpen}
              trackEvent={{
                label: 'Contact Us',
              }}
              showModal={() => {
                showModal();
                trackAnalytics('TrialContactClick', {
                  TrialId: searchResult.org_study_id,
                  PositionInList: -1,
                  TrialTitle: searchResult.brief_title,
                  TrialStatus: searchResult.display_status_internal,
                  LocationPosition: index,
                  Conditions: searchResult.conditions.map(
                    (cond) => cond.InternalValue
                  ),
                });
              }}
              hideModal={hideModal}
              button={'Contact us'}
            >
              <ContactUsWidget
                print={false}
                personName={
                  row.Contact.first_name + ' ' + row.Contact.last_name
                }
                personPhone={row.Contact.phone}
                personEmail={row.Contact.email}
                centerName={
                  (searchResult.overall_contact.first_name
                    ? searchResult.overall_contact.first_name
                    : '') +
                  ' ' +
                  (searchResult.overall_contact.last_name
                    ? searchResult.overall_contact.last_name
                    : '')
                }
                centerEmail={null}
                centerPhone={searchResult.overall_contact.phone}
                centerBkpName={
                  searchResult.overall_contact_backup
                    ? (searchResult.overall_contact_backup.first_name
                        ? searchResult.overall_contact_backup.first_name + ' '
                        : '') +
                      (searchResult.overall_contact_backup.last_name
                        ? searchResult.overall_contact_backup.last_name
                        : '')
                    : null
                }
                centerBkpEmail={null}
                centerBkpPhone={
                  searchResult.overall_contact_backup
                    ? searchResult.overall_contact_backup.phone
                    : null
                }
                hideModal={hideModal}
              />
            </Modal>
          </Td>
        </Tr>
        <Tr
          className={
            'contact-print location-row ' +
            (index % 2 === 1 ? 'row-unpaired' : 'row-paired')
          }
        >
          <Td className={'location'}>{row.Name}</Td>
          {row.City ? <Td>{row.City}</Td> : <Td>N/A</Td>}
          {row.StateDisplay ? <Td>{row.StateDisplay}</Td> : <Td>N/A</Td>}
          {row.PostalCode ? <Td>{row.PostalCode}</Td> : <Td>N/A</Td>}
          {row.CountryDisplay ? <Td>{row.CountryDisplay}</Td> : <Td>N/A</Td>}
          <Td className={'status-line'}>
            <div
              className={
                `${row.StatusMapped}`.toLocaleLowerCase() + ' status-content'
              }
            >
              {row.StatusMapped}
            </div>
          </Td>
          <Td className={'modal-status'}>
            <Modal
              type={'contactUs'}
              open={modalOpen}
              showModal={showModal}
              hideModal={hideModal}
              button={'Contact us'}
              trackEvent={{
                label: 'Contact Us',
              }}
            >
              <ContactUsWidget
                print={false}
                personName={
                  row.Contact.first_name + ' ' + row.Contact.last_name
                }
                personPhone={row.Contact.phone}
                personEmail={row.Contact.email}
                centerName={
                  (searchResult.overall_contact.first_name
                    ? searchResult.overall_contact.first_name
                    : '') +
                  ' ' +
                  (searchResult.overall_contact.last_name
                    ? searchResult.overall_contact.last_name
                    : '')
                }
                centerEmail={null}
                centerPhone={searchResult.overall_contact.phone}
                centerBkpName={
                  searchResult.overall_contact_backup
                    ? (searchResult.overall_contact_backup.first_name
                        ? searchResult.overall_contact_backup.first_name + ' '
                        : '') +
                      (searchResult.overall_contact_backup.last_name
                        ? searchResult.overall_contact_backup.last_name
                        : '')
                    : null
                }
                centerBkpEmail={null}
                centerBkpPhone={
                  searchResult.overall_contact_backup
                    ? searchResult.overall_contact_backup.phone
                    : null
                }
                hideModal={hideModal}
              />
            </Modal>
          </Td>
        </Tr>
        <Tr
          className={
            'contact-print location-row ' +
            (index % 2 === 1 ? 'row-unpaired' : 'row-paired')
          }
        >
          <Td colSpan={7}>
            <ContactUsWidget
              print={true}
              personName={row.Contact.first_name + ' ' + row.Contact.last_name}
              personPhone={row.Contact.phone}
              personEmail={row.Contact.email}
              centerName={
                (searchResult.overall_contact.first_name
                  ? searchResult.overall_contact.first_name
                  : '') +
                ' ' +
                searchResult.overall_contact.last_name
              }
              centerEmail={null}
              centerPhone={searchResult.overall_contact.phone}
              centerBkpName={
                searchResult.overall_contact_backup
                  ? (searchResult.overall_contact_backup.first_name
                      ? searchResult.overall_contact_backup.first_name + ' '
                      : '') + searchResult.overall_contact_backup.last_name
                  : null
              }
              centerBkpEmail={null}
              centerBkpPhone={
                searchResult.overall_contact_backup
                  ? searchResult.overall_contact_backup.phone
                  : null
              }
              hideModal={hideModal}
            />
          </Td>
        </Tr>
      </Fragment>
    );
  };

  const renderResultsTable = () => {
    if (!searchResult && !searchResult.length) {
      return undefined;
    }
    let locations = sortLocations(
      activeLocations,
      searchLong,
      searchLat,
      'distance'
    );

    return (
      <Table>
        <Thead className={'thead-mobile'}>
          <Tr>
            <Th className={'headerElement first'}>Locations</Th>
            <Th className={'headerElement'}>City</Th>
            <Th className={'headerElement'}>State/ province</Th>
            <Th className={'headerElement'}>Postal Code</Th>
            <Th className={'headerElement'}>Country</Th>
            <Th className={'headerElement'}>Status</Th>
            <Th className={'headerElement'}>
              <span className={'accesibility-feature'}>more</span>
            </Th>
          </Tr>
        </Thead>
        <Tbody>{locations.map(renderTableRow)}</Tbody>
      </Table>
    );
  };

  const renderContent = () => {
    // const { activeMarker, searchResult, isLoading } = state;
    let mapContent = data.trialDetailsJson.mapSection;

    if (isLoading) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    if (!searchResult) {
      return null;
    }

    const trialCitations = (searchResult.citations || []).filter(
      (c) => c.citation.length > 0
    );

    return (
      <>
        <div className={'mobile-detailsPage-container'}>
          <DetailsHero>
            <div className="container-fluid details-page">
              <Fonts>
                <Row className={'top-navigation-row'}>
                  <Col
                    lg={8}
                    md={6}
                    sm={6}
                    xs={12}
                    className={'status-bar-desktop'}
                  >
                    <button
                      className="go-back-button"
                      onClick={(event) => {
                        event.preventDefault();
                        window.history.go(-1);
                      }}
                    >
                      {data.trialDetailsJson.returnSearch}
                    </button>
                  </Col>
                  <Col lg={4} md={6} sm={6} xs={12} className="social-sharing">
                    <SocialSharing mailSubject={searchResult.brief_title} />
                  </Col>
                </Row>
                <Row className={'data-display-row'}>
                  <Col md={12} lg={12} className={'hero-title-col'}>
                    {searchResult.brief_title ? (
                      <div>
                        <h1 className="details-title">
                          Trial Condition(s): <br></br>
                          {searchResult.conditions &&
                          searchResult.conditions.length
                            ? filteringUniq(
                                searchResult.conditions.map(
                                  (condition) => condition.DisplayValue
                                )
                              ).join(', ')
                            : 'N/A'}
                        </h1>
                      </div>
                    ) : (
                      <h1 className="details-title">
                        {searchResult.official_title}
                      </h1>
                    )}
                    <div className={'overall-section'}>
                      <div
                        className={
                          `${searchResult.display_status}`.toLocaleLowerCase() +
                          ' status-display' +
                          (`${searchResult.display_status}` ===
                          'Terminated/Withdrawn'
                            ? ' terminated'
                            : '')
                        }
                      >
                        {searchResult.display_status}
                      </div>
                      <Button
                        id="PopoverClickOverall"
                        type="right"
                        className={'popoverBtn'}
                      >
                        {' '}
                        <span className={'accesibility-feature'}>more</span>
                        <FaInfo />
                      </Button>
                      <UncontrolledPopoverStyledSections
                        trigger={isMobile ? 'click' : 'hover'}
                        placement={isMobile ? 'right' : 'right'}
                        target="PopoverClickOverall"
                      >
                        <PopoverHeader>
                          {searchResult.display_status}
                        </PopoverHeader>
                        {searchResult.display_status === 'Trial Completed' ? (
                          <PopoverBody>
                            {data.trialDetailsJson.popoversText.completed}
                          </PopoverBody>
                        ) : searchResult.display_status === 'Recruiting' ? (
                          <PopoverBody>
                            {data.trialDetailsJson.popoversText.recruiting}
                          </PopoverBody>
                        ) : searchResult.display_status ===
                          'Not Yet Recruiting' ? (
                          <PopoverBody>
                            {data.trialDetailsJson.popoversText.notRecruiting}
                          </PopoverBody>
                        ) : searchResult.display_status === 'Not Recruiting' ? (
                          <PopoverBody>
                            {data.trialDetailsJson.popoversText.notRecruiting}
                          </PopoverBody>
                        ) : searchResult.display_status ===
                          'Terminated / Withdrawn' ? (
                          <PopoverBody>
                            {data.trialDetailsJson.popoversText.terminated}
                          </PopoverBody>
                        ) : searchResult.display_status === 'Terminated' ? (
                          <PopoverBody>
                            {data.trialDetailsJson.popoversText.terminated}
                          </PopoverBody>
                        ) : (
                          ''
                        )}
                        <PopoverBody />
                      </UncontrolledPopoverStyledSections>
                    </div>

                    <div className={'main-description'}>
                      {searchResult.official_title}
                    </div>
                  </Col>
                  <Col
                    className={'id-cols'}
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <h3>{data.trialDetailsJson.identifier}:</h3>
                    <Button
                      id="PopoverClickidentifier"
                      type="right"
                      className={'popoverBtn'}
                    >
                      <span className={'accesibility-feature'}>more</span>
                      <FaInfo />
                    </Button>
                    <UncontrolledPopoverStyledSections
                      trigger={isMobile ? 'click' : 'hover'}
                      placement={isMobile ? 'right' : 'right'}
                      target="PopoverClickidentifier"
                    >
                      <PopoverHeader>Protocol ID</PopoverHeader>
                      <PopoverBody>
                        {data.trialDetailsJson.popoversText.protocolId}
                      </PopoverBody>
                    </UncontrolledPopoverStyledSections>
                    <p>{searchResult.org_study_id}</p>
                  </Col>
                  <Col className={'id-cols'} xs={12} sm={12} md={4} lg={3}>
                    <h3>{data.trialDetailsJson.clinicalID}:</h3>
                    <Button
                      id="clinicalID"
                      type="right"
                      className={'popoverBtn'}
                    >
                      <span className={'accesibility-feature'}>more</span>
                      <FaInfo />
                    </Button>
                    <UncontrolledPopoverStyledSections
                      trigger={isMobile ? 'click' : 'hover'}
                      placement={isMobile ? 'left' : 'top'}
                      target="clinicalID"
                    >
                      <PopoverHeader>ClinicalTrials.gov ID</PopoverHeader>
                      <PopoverBody>
                        {data.trialDetailsJson.popoversText.clinicalTrialsID}
                      </PopoverBody>
                    </UncontrolledPopoverStyledSections>

                    <p>
                      {searchResult.nct_number ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://clinicaltrials.gov/study/${searchResult.nct_number}?rank=1`}
                        >
                          {searchResult.nct_number}
                        </a>
                      ) : (
                        'Not Available'
                      )}
                    </p>
                  </Col>
                  {eudraCtId ? (
                    <Col
                      className={'id-cols'}
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      xl={2}
                    >
                      <h3>{data.trialDetailsJson.EudraCtID}:</h3>
                      <Button
                        id="eudraCtID"
                        type="right"
                        className={'popoverBtn'}
                      >
                        <span className={'accesibility-feature'}>more</span>
                        <FaInfo />
                      </Button>
                      <UncontrolledPopoverStyledSections
                        trigger={isMobile ? 'click' : 'hover'}
                        placement={isMobile ? 'right' : 'right'}
                        target="eudraCtID"
                      >
                        <PopoverHeader>EudraCT ID</PopoverHeader>
                        <PopoverBody>
                          {data.trialDetailsJson.popoversText.eudraCtId}
                        </PopoverBody>
                      </UncontrolledPopoverStyledSections>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://www.clinicaltrialsregister.eu/ctr-search/search?query=${eudraCtId}`}
                          title={eudraCtId}
                        >
                          {eudraCtId}
                        </a>
                      </p>
                    </Col>
                  ) : null}
                  {ctisId ? (
                    <Col
                      className={'id-cols'}
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      xl={2}
                    >
                      <h3>{data.trialDetailsJson.CtisId}:</h3>
                      <Button id="ctisId" type="right" className={'popoverBtn'}>
                        <span className={'accesibility-feature'}>more</span>
                        <FaInfo />
                      </Button>
                      <UncontrolledPopoverStyledSections
                        trigger={isMobile ? 'click' : 'hover'}
                        placement={isMobile ? 'right' : 'right'}
                        target="ctisId"
                      >
                        <PopoverHeader>EU CT Number</PopoverHeader>
                        <PopoverBody>
                          {data.trialDetailsJson.popoversText.ctisId}
                        </PopoverBody>
                      </UncontrolledPopoverStyledSections>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://euclinicaltrials.eu/app/#/view/${ctisId}?lang=en`}
                          title={ctisId}
                        >
                          {ctisId}
                        </a>
                      </p>
                    </Col>
                  ) : null}
                </Row>
              </Fonts>
            </div>
          </DetailsHero>

          {searchResult.attachments !== null ||
          searchResult.PatientLevelData === 'true' ||
          (searchResult.HasResults === 'true' && searchResult.nct_number) ? (
            <DownloadSectionDetails
              study={searchResult}
              labels={data.searchResultJson.viewResultsModal}
              tooltips={data.trialDetailsJson.popoversText}
              isMobile={isMobile}
              companyData={getCompanyData(
                data.trialDetailsJson,
                'individualPatientDataUrl'
              )}
            />
          ) : undefined}
          <TrialPurposeStyle>
            <div className={'content-container'}>
              <Fonts>
                {trialCitations && trialCitations.length > 0 ? (
                  <div className="publicationsContainer">
                    <h2>{'Clinical Publication(s)'}</h2>
                    <div>
                      {showCitations ? (
                        trialCitations.map((citation, index) => (
                          <p key={index}>{citation.citation}</p>
                        ))
                      ) : (
                        <p>{trialCitations[0].citation}</p>
                      )}
                    </div>
                    {trialCitations.length > 1 ? (
                      <button
                        onClick={() => showCitationsFnc()}
                        className={'showBtn'}
                      >
                        {showCitations
                          ? 'SHOW LESS PUBLICATIONS'
                          : 'SHOW MORE PUBLICATIONS'}
                      </button>
                    ) : null}
                  </div>
                ) : null}
                <h2>{data.trialDetailsJson.trialPurpose}</h2>
                <p className="whitespace-pre-wrap">
                  {searchResult.brief_summary}
                </p>
              </Fonts>
            </div>
          </TrialPurposeStyle>
          <TrialSummaryStyle>
            <ShadowSecDetails />
            <Fonts>
              <Row className={'trial-summary-row'}>
                <Col lg={12} md={12} className={'summaryTitle'}>
                  <h2>{data.trialDetailsJson.trialSummary}</h2>
                </Col>

                <Col md={8} lg={8} className={'cards-holder'}>
                  <Row>
                    {searchResult.conditions ? (
                      <Col xs={12} sm={6} md={6} lg={3} className={'cards-col'}>
                        <Card className={'detail-card'}>
                          <CardBody>
                            <Button
                              id="condition"
                              type="right"
                              className={'popoverBtn'}
                            >
                              <span className={'accesibility-feature'}>
                                more
                              </span>
                              <FaInfo />
                            </Button>
                            <UncontrolledPopoverStyledSections
                              trigger={isMobile ? 'click' : 'hover'}
                              placement={isMobile ? 'left' : 'right'}
                              target="condition"
                            >
                              <PopoverHeader>Medical Condition</PopoverHeader>
                              <PopoverBody>
                                {
                                  data.trialDetailsJson.popoversText
                                    .medicalCondition
                                }
                              </PopoverBody>
                            </UncontrolledPopoverStyledSections>
                            <div className={'img-holder'}>
                              <img
                                className={'summary-icon condition'}
                                src={'/images/medical-condition.png'}
                                alt={
                                  'Clinical Trial Medical Condition and Disease'
                                }
                              />
                            </div>
                            <h4>
                              {searchResult.conditions &&
                              searchResult.conditions.length
                                ? filteringUniq(
                                    searchResult.conditions.map(
                                      (condition) => condition.DisplayValue
                                    )
                                  ).join(', ')
                                : 'N/A'}
                            </h4>
                          </CardBody>
                        </Card>
                      </Col>
                    ) : undefined}
                    <Col
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      className={
                        ['All', 'Both'].indexOf(searchResult.gender) >= 0
                          ? 'both' + ' gender-icon'
                          : `${searchResult.gender}`.toLocaleLowerCase() +
                            ' gender-icon'
                      }
                    >
                      <Card className={'detail-card'}>
                        <CardBody>
                          <div className={'img-holder'}>
                            {searchResult.display_gender.indexOf('Both') >
                            -1 ? (
                              <img
                                className={'summary-icon gender'}
                                src={'/images/Gender-Female-Male.png'}
                                alt={
                                  'Clinical Trial Gender and Sex Female and Male'
                                }
                              />
                            ) : (
                              <img
                                className={'summary-icon gender'}
                                src={
                                  searchResult.display_gender &&
                                  `/images/${searchResult.display_gender}.png`
                                    ? `/images/${searchResult.display_gender}.png`
                                    : '/images/Gender-Female-Male.png'
                                }
                                alt={
                                  'Clinical Trial Gender and Sex Female and Male'
                                }
                              />
                            )}
                          </div>
                          <h4 className={'results-gender'}>
                            {['All', 'Both'].indexOf(searchResult.gender) >= 0
                              ? 'Female & Male'
                              : searchResult.display_gender}
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      className={'text-requirements'}
                    >
                      <Card className={'detail-card'}>
                        <CardBody>
                          <div className={'img-holder'}>
                            <img
                              className={'summary-icon age'}
                              src={'/images/age-range.png'}
                              alt={'Clinical Trial Age Range or Limit'}
                            />
                          </div>
                          <h4 className={'results-ageRange'}>
                            {searchResult.minimum_age_raw &&
                            searchResult.maximum_age_raw &&
                            searchResult.minimum_age_raw !== 'N/A' &&
                            searchResult.maximum_age_raw !== 'N/A'
                              ? `${searchResult.minimum_age_raw} - ${searchResult.maximum_age_raw}`
                              : searchResult.minimum_age &&
                                searchResult.maximum_age === 'N/A' &&
                                searchResult.minimum_age !== 'N/A'
                              ? `${searchResult.minimum_age} + Years`
                              : searchResult.maximum_age === 'N/A' &&
                                searchResult.minimum_age === 'N/A'
                              ? ' 0 + Years'
                              : searchResult.minimum_age === null ||
                                searchResult.minimum_age === 'N/A'
                              ? ' 0 + Years'
                              : `up to ${searchResult.maximum_age} Years`}
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} className={'cards-col'}>
                      <Card className={'detail-card'}>
                        <CardBody>
                          <Button
                            id="criteria"
                            type="right"
                            className={'popoverBtn'}
                          >
                            <span className={'accesibility-feature'}>more</span>
                            <FaInfo />
                          </Button>
                          <UncontrolledPopoverStyledSections
                            trigger={isMobile ? 'click' : 'hover'}
                            placement={isMobile ? 'left' : 'right'}
                            target="criteria"
                          >
                            <PopoverHeader>Inclusion & Exclusion</PopoverHeader>
                            <PopoverBody>
                              <p>
                                {
                                  data.trialDetailsJson.popoversText
                                    .inclusionCriteria
                                }
                              </p>
                              <p>
                                {
                                  data.trialDetailsJson.popoversText
                                    .exclusionCriteria
                                }
                              </p>
                            </PopoverBody>
                          </UncontrolledPopoverStyledSections>
                          <div className={'img-holder'}>
                            <img
                              className={'summary-icon age'}
                              src={'/images/inclusion-exclusion-criteria.png'}
                              alt={
                                'Clinical Trial Inclusion and Exclusion Criteria Allowing or Preventing People to Participate'
                              }
                            />
                          </div>
                          {searchResult.criteria ? (
                            <h4>Inclusion | Exclusion</h4>
                          ) : (
                            <h4>Not Available</h4>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} className={'cards-col'}>
                      <Card className={'detail-card'}>
                        <CardBody>
                          <div className={'img-holder'}>
                            <img
                              className={'summary-icon capsula'}
                              src={'/images/treatment.png'}
                              alt={'Clinical Trial Drug Treatment'}
                            />
                          </div>
                          {searchResult.product_name ? (
                            <h4>{searchResult.product_name}</h4>
                          ) : (
                            <h4>Not Available</h4>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} className={'cards-col'}>
                      <Card className={'detail-card'}>
                        <CardBody>
                          <Button
                            id="study_type"
                            type="right"
                            className={'popoverBtn'}
                          >
                            <span className={'accesibility-feature'}>more</span>
                            <FaInfo />
                          </Button>
                          <UncontrolledPopoverStyledSections
                            trigger={isMobile ? 'click' : 'hover'}
                            placement={isMobile ? 'left' : 'right'}
                            target="study_type"
                          >
                            <PopoverHeader>Study Type</PopoverHeader>
                            <PopoverBody>
                              {data.trialDetailsJson.popoversText.studyType}
                            </PopoverBody>
                          </UncontrolledPopoverStyledSections>
                          <div className={'img-holder'}>
                            <img
                              className={'summary-icon study'}
                              src={'/images/study-type.png'}
                              alt={
                                'Clinical Trial Type Observational or Interventional Study'
                              }
                            />
                          </div>
                          {searchResult.study_type ? (
                            <h4>
                              {searchResult.study_type.replace(
                                'Observational',
                                'Non-Interventional'
                              )}
                            </h4>
                          ) : (
                            <h4>{'Not Available'}</h4>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} className={'cards-col'}>
                      <Card className={'detail-card'}>
                        <CardBody>
                          <div className={'img-holder'}>
                            <img
                              className={'summary-icon phase'}
                              src={'/images/study-phase.png'}
                              alt={'Clinical Trial Phases'}
                            />
                          </div>
                          {searchResult.display_phase ? (
                            <h4>{searchResult.display_phase} </h4>
                          ) : (
                            <h4>{'Not Available'}</h4>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} className={'cards-col'}>
                      <Card className={'detail-card'}>
                        <CardBody>
                          <Button
                            id="period"
                            type="right"
                            className={'popoverBtn'}
                          >
                            <span className={'accesibility-feature'}>more</span>
                            <FaInfo />
                          </Button>
                          <UncontrolledPopoverStyledSections
                            trigger={isMobile ? 'click' : 'hover'}
                            placement={isMobile ? 'left' : 'right'}
                            target="period"
                          >
                            <PopoverHeader>Trial Duration</PopoverHeader>
                            <PopoverBody>
                              {data.trialDetailsJson.popoversText.trialDuration}
                            </PopoverBody>
                          </UncontrolledPopoverStyledSections>
                          <div className={'img-holder'}>
                            <img
                              className={'summary-icon calendar'}
                              src={'/images/study-duration.png'}
                              alt={
                                'Clinical Trial Duration from First Patient In to Last Patient Out'
                              }
                            />
                          </div>
                          {searchResult.start_date ? (
                            <h4 className={'dates '}>
                              <Moment format="MMMYYYY ">
                                {searchResult.start_date}
                              </Moment>
                              &#45;
                              {searchResult.last_follow_up_date ? (
                                <Moment format=" MMMYYYY ">
                                  {searchResult.last_follow_up_date}
                                </Moment>
                              ) : undefined}
                            </h4>
                          ) : (
                            <h4>{'Not Available'}</h4>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={4} className={'criteria-Col'}>
                  <h2>
                    Inclusion and Exclusion<br></br>Criteria
                  </h2>
                  <Modal
                    type={'criteriaModal'}
                    open={crtModalOpen}
                    trackEvent={{
                      label: 'Inclusion and Exclusion Criteria',
                    }}
                    showModal={() => {
                      showCrtModal();
                      trackAnalytics('InclusionExclusionClick', {
                        TrialId: searchResult.org_study_id,
                        PositionInList: -1,
                        TrialTitle: searchResult.brief_title,
                        TrialStatus: searchResult.display_status_internal,
                        Conditions: searchResult.conditions.map(
                          (cond) => cond.InternalValue
                        ),
                      });
                    }}
                    hideModal={hideCrtModal}
                    button={'Read More'}
                  >
                    <Criteria
                      textInc={searchResult.inclusion}
                      textExc={searchResult.exclusion}
                      hideModal={hideCrtModal}
                    />
                  </Modal>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className={'criteria-print'}>
                  <h2>{'Inclusion Criteria'}</h2>
                  {searchResult.inclusion ? (
                    <pre>{searchResult.inclusion}</pre>
                  ) : (
                    <pre>{'Sorry there is no Inclusion Criteria'}</pre>
                  )}

                  <h2 className={'exclusion'}>{'Exclusion Criteria'}</h2>
                  {searchResult.exclusion ? (
                    <pre>{searchResult.exclusion}</pre>
                  ) : (
                    <pre>{'Sorry there is no Exclusion Criteria'}</pre>
                  )}
                </Col>
              </Row>
            </Fonts>
          </TrialSummaryStyle>
          {searchResult.locations && searchResult.locations.length ? (
            <MapSectionStyle>
              <ShadowSecDetails />
              <div className="container-fluid details-page mapSection">
                <Fonts>
                  <h2>Where to Participate</h2>
                  <div className={'map-holder'}>
                    <div
                      // ref={ref => (mapHolderRef = ref)}
                      className="map-locations"
                      id={'map-locations'}
                    >
                      <Map
                        ref={mapRef}
                        google={google}
                        containerStyle={{
                          width: '100%',
                          height: '100%',
                          position: 'relative',
                        }}
                        className={'results-map'}
                        initialCenter={{
                          lat:
                            parseFloat(searchResult.locations[0].Latitude) ||
                            50.43,
                          lng:
                            parseFloat(searchResult.locations[0].Longitude) ||
                            26.081807,
                        }}
                      >
                        {null &&
                          searchResult.locations.map((marker, index) => (
                            <Marker
                              key={index}
                              title={marker.Name}
                              locationDetails={marker}
                              id={'marker_' + index}
                              position={{
                                lat: marker.Latitude,
                                lng: marker.Longitude,
                              }}
                              draggable={false}
                            />
                          ))}
                        <InfoWindow
                          maxWidth={350}
                          marker={activeMarker}
                          onClose={onInfoWindowClose}
                          visible={showingInfoWindow}
                        >
                          {activeMarker &&
                          activeMarker.markersClusterKey &&
                          markerDescriptions[activeMarker.markersClusterKey] &&
                          activeMarker.locationDetails &&
                          (activeMarker.locationDetails.Name ||
                            activeMarker.locationDetails.City) ? (
                            <div>
                              {markerDescriptions[
                                activeMarker.markersClusterKey
                              ].map((locationDetails, idx) => (
                                <div
                                  key={idx}
                                  className="text-left marker-info-window"
                                  style={{ padding: '5px' }}
                                >
                                  <strong>
                                    {locationDetails.Name != null
                                      ? locationDetails.Name
                                      : 'Not available'}
                                  </strong>
                                  <br />
                                  <strong>{mapContent.city}</strong>:{' '}
                                  {locationDetails.City != null
                                    ? locationDetails.City
                                    : 'Not available'}
                                  <br />
                                  <strong>{mapContent.state}</strong>:{' '}
                                  {locationDetails.StateDisplay != null
                                    ? locationDetails.StateDisplay
                                    : 'Not available'}
                                  <br />
                                  <strong>{mapContent.zip}</strong>:{' '}
                                  {locationDetails.PostalCode != null
                                    ? locationDetails.PostalCode
                                    : 'Not Available'}
                                  <br />
                                  <strong>{mapContent.country}</strong>:{' '}
                                  {locationDetails.CountryDisplay != null
                                    ? locationDetails.CountryDisplay
                                    : 'Not available'}
                                  {locationDetails.Contact &&
                                  locationDetails.Contact.phone ? (
                                    <>
                                      <br />
                                      <a
                                        href={`tel:${locationDetails.Contact.phone}`}
                                      >
                                        {locationDetails.Contact.phone}
                                        <span
                                          className={'accesibility-feature'}
                                        >
                                          more
                                        </span>
                                      </a>
                                    </>
                                  ) : undefined}
                                  {locationDetails.Contact &&
                                  locationDetails.Contact.email ? (
                                    <>
                                      <br />
                                      <a
                                        href={`mailto:${locationDetails.Contact.email}`}
                                      >
                                        {locationDetails.Contact.email}
                                        <span
                                          className={'accesibility-feature'}
                                        >
                                          more
                                        </span>
                                      </a>
                                    </>
                                  ) : undefined}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div />
                          )}
                        </InfoWindow>
                      </Map>
                    </div>
                  </div>
                  <div className={'location-holder'}>
                    {renderResultsTable()}
                  </div>
                  {activeLocations.length > 4 ? (
                    <div className={'button-holder'}>
                      {openTable === false ? (
                        <button onClick={openLocationTable}>
                          SHOW MORE LOCATIONS
                        </button>
                      ) : (
                        <button onClick={closeLocationTable}>
                          SHOW LESS LOCATIONS
                        </button>
                      )}
                    </div>
                  ) : (
                    <div style={{ marginBottom: '40px' }} />
                  )}
                </Fonts>
              </div>
            </MapSectionStyle>
          ) : undefined}
        </div>
      </>
    );
  };

  const renderStudyNotFound = () => {
    return (
      <Fonts>
        <StudyNotFoundContainer>
          <h1 className={'main-title'}>{data.studyNotFoundJson.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.studyNotFoundJson.content.childMarkdownRemark.html,
            }}
          />
        </StudyNotFoundContainer>
      </Fonts>
    );
  };

  // render() {
  const content = renderContent();
  const studyNotFound = renderStudyNotFound();

  return loading || !searchResult ? (
    apiBadResponse ? (
      <Layout>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ maxWidth: '1250px', width: '100%' }}>
            {studyNotFound}
          </div>
        </div>
      </Layout>
    ) : (
      ''
    )
  ) : (
    <Layout
      meta={replaceCompanyName({
        pageTitle: `%%COMPANYNAME%% - ${searchResult.brief_title}`,
        pageDescription: searchResult.official_title,
        canonical: window.location.href.split('&')[0],
      })}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: '1250px', width: '100%' }}>{content}</div>
      </div>
    </Layout>
  );
};

TrialDetails.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  navigate: PropTypes.func,
  google: PropTypes.object.isRequired,
};

export default GoogleApiWrapper({
  apiKey: gMapApiKey(),
})(TrialDetails);
export const query = graphql`
  query ResultsDetailsQuery {
    studyNotFoundJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    trialDetailsJson {
      title
      identifier
      clinicalID
      EudraCtID
      CtisId
      additionalQuestions
      returnSearch
      socialMedia
      trialPurpose
      keyRequirement
      mapSectionTitle
      mapLocation
      trialSummary
      trialDesign
      summarySection {
        conditionsDisplay
        dates
        phase
        studyType
        participationDuration
        acceptVolunteers
      }
      mapSection {
        city
        state
        zip
        country
      }
      popoversText {
        studyType
        recruiting
        completed
        notRecruiting
        terminated
        inclusionCriteria
        exclusionCriteria
        medicalCondition
        resultSummary
        statisticalPlan
        protocol
        trialDuration
        eudraCtId
        ctisId
        protocolId
        clinicalStudyReport
        clinicalTrialsID
        studyResults
      }
      individualPatientDataUrl
      individualPatientDataUrl_merck
    }
    searchResultJson {
      viewResultsModal {
        dropdownLanguageTitle
        dropdownLanguagePlaceholder
        downloadBtn
        completeDownloadTitle
        completeDownloadDesc
        completeDownloadBtn
      }
    }
  }
`;
